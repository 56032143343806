import React, { useEffect, useRef } from 'react';
import { TgUSerAuth } from '../../../../Types/Types';

declare global {
    interface Window {
        onTGAuthCallback: (user: TgUSerAuth) => void
    }
}  

export const TelegramAuth = () => {
    const ref = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://telegram.org/js/telegram-widget.js?22'
        script.setAttribute('data-telegram-login', "timetaskBot")
        script.setAttribute('data-size', 'large')
        script.setAttribute('data-userpic', 'false')
        script.setAttribute('data-radius', '8')
        script.setAttribute('data-onauth', 'onTGAuthCallback(user)')
        script.setAttribute('data-request-access', 'write')

        if (ref.current) {
            ref.current.appendChild(script)
        }

    }, [])

    return (
        <div ref={ref} id="telegram-widget-container">
        </div>
    )
}