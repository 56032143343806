import React, { useEffect, useRef, useState } from "react"
import Button from "../../../../UIcomponents/Button/Button"
import './Integrations.css'
import request from "../../../../Requests/requests"
import { useDispatch, useSelector } from "react-redux"
import { loadUserSettings } from "../../../../store/settingsReducer"
import ModalWindow from "../../../../ModalWindow/ModalWindow/ModalWindow"
import DefaultInput from "../../../../UIcomponents/DefaultInput/DefaultInput"
import DropDown from "../../../../UIcomponents/DropDown/DropDown"
import { useOutsideClick } from "../../../../customHooks/useOutsideClick"
import InputSelect from "../../../../UIcomponents/InputSelect/InputSelect"
import { PayloadDropDown } from "../../../../UIcomponents/DropDown/dropDownType/dropDownType"
import { SettingsUserState } from "../../../../Types/Types"
import { TelegramAuth } from "./telegrammAuth"

interface IntegrationProps {
    setAlert: Function,
    setAlertTitle: Function,
    setTypeAlert: Function
}

export default function Integrations({setAlert, setAlertTitle, setTypeAlert}: IntegrationProps) {
    const dispatch = useDispatch();
    const [modalShow, setModalShow] = useState(false);
    const [dropDownDuration, setDropDownDuration] = useState(false);
    const [dropDownExecution, setDropDownExecution] = useState(false);
    const [gCalendarTag, setGCalendarTag] = useState('');
    const [eventDuration, setEventDuration] = useState(0);
    const [executionAction, setExecutionAction] = useState(0);
    const [trackedChanges, setTrackedChanges] = useState<string[]>([])
    const settingsUser = useSelector((state: SettingsUserState)=> state.settings.settings)
    const [saveBtnDisable, setSaveBtnDisable] = useState<boolean>(true)
    const [loaderGoogle, setLoaderGoogle] = useState<boolean>(false)
    const [loaderTg, setLoaderTg] = useState<boolean>(false)
    const [loaderSaving, setLoaderSaving] = useState<boolean>(false)
    const [windowScreen, setWindowScreen] = useState(window.screen.width);
    // const optionArr: string[] = ['15 минут', '30 минут', '60 минут', '90 минут']
    const optionArrAction: string[] = ['Удаляются из Google Календарь', 'Остаются в Google Каледнарь']
    const refDuration = useRef(null);
    const refExecution = useRef(null);
    useOutsideClick(refDuration, () => setDropDownDuration(false), dropDownDuration)
    useOutsideClick(refExecution, () => setDropDownExecution(false), dropDownExecution)

    useEffect(() => {
        if (!modalShow) {
            setSaveBtnDisable(true)
        }
    }, [modalShow])

    useEffect(() => {
        const handleUpdateScreenWidth = () => {
          setWindowScreen(window.screen.width)
        }
    
        window.addEventListener('resize', handleUpdateScreenWidth);
    
        return () => {
          window.removeEventListener('resize', handleUpdateScreenWidth);
        };
    }, [windowScreen])

    const checkChanges = () => {
        if (!trackedChanges.includes(`${executionAction}`)) {
            setSaveBtnDisable(false)
        }
    }

    const saveUserSettings = () => {
        setLoaderSaving(true)
        request.post('/GoogleCalendar/Settings', {
            userId: localStorage.getItem('token'),
            gCalendarTag: gCalendarTag,
            eventDuration: eventDuration,
            executionAction: executionAction
        }).then(() => {
            setLoaderSaving(false)
            setModalShow(false)
            setAlert(true)
        })
    }

    const updateGCalendarTag = (payload: string) => {
        setGCalendarTag(payload)
        checkChanges()
    }

    // const updateEventDuration = (payload: PayloadDropDown) => {
    //     setEventDuration(payload.index + 1)
    //     checkChanges()
    // }

    const updateExecutionAction = (payload: PayloadDropDown) => {
        setExecutionAction(payload.index + 1)
        checkChanges()
    }

    window.onTGAuthCallback = function (user) {
        request.post('/Telegram/TelegramId', {
            userId: localStorage.getItem('token'),
            telegramId: `${user.id}`
        }).then(() => {
            getUserSettings()
        })
    }

    const removeIntegrationTg = () => {
        setLoaderTg(true)
        request.post('/Telegram/Bot/Cancel', {
            userId: localStorage.getItem('token')
        })
            .then(() => {getUserSettings()
                setLoaderTg(false)
                setAlert(true)
                setAlertTitle('Интеграция отключена')
                setTypeAlert('warningAl')})
            .catch((error) => {                
                setLoaderTg(false)
                setAlert(true)
                setAlertTitle(`${error?.message}`)
                setTypeAlert('danger')
            })
    }

    const getUserSettings = () => {
        request.get('/Settings/', {
            params: { UserId: localStorage.getItem('token') }
          })
            .then((response) => dispatch(loadUserSettings(response.data)))
    }

    const removeIntegration = () => {
        setLoaderGoogle(true)
        request.post('/GoogleCalendar/Cancel?UserId=' + localStorage.getItem('token'))
            .then(() => {getUserSettings()
                setLoaderGoogle(false)
                setAlert(true)
                setAlertTitle('Интеграция отключена')
                setTypeAlert('warningAl')})
            .catch((error) => {                
                setLoaderGoogle(false)
                setAlert(true)
                setAlertTitle(`${error?.message}`)
                setTypeAlert('danger')
            })
    }
    
    const getGCalendarSettings = () => {
        request.get('/GoogleCalendar/Settings?UserId=' + localStorage.getItem('token'))
            .then((response) => {
                setGCalendarTag(response.data.gCalendarTag)
                setEventDuration(response.data.eventDuration)
                setExecutionAction(response.data.executionAction)
                const valuesObj: string[] = Object.values(response.data)
                setTrackedChanges(valuesObj)
            })
    }

    useEffect(() => {
        if (modalShow) {
            getGCalendarSettings()
        }
    }, [modalShow])

    const GetUrlGoogle = () => {
        setLoaderGoogle(true)
        request.get('/GoogleCalendar/Url')
        .then(response => {
            setLoaderGoogle(false)
            window.location.href = `${response.data}`
        }).catch(error => {
            setLoaderGoogle(false)
            console.log(error)
        })
    }
    
    return (
        <>
            {modalShow &&
                <ModalWindow
                updateModalState={() => {setModalShow(false)
                    setDropDownDuration(false)
                    setDropDownExecution(false)
                    setGCalendarTag('')
                }}
                modalShow={modalShow}
                headerSize={windowScreen > 576 ? 16 : 14}
                position={'center'}
                titleType={'default'}
                title={'Настройки интеграции с Google Календарь'}>
                    <div style={{ width: `${windowScreen > 576 ? '444px' : '319px'}`}}>
                        <div style={{display: 'flex', flexDirection: 'column', gap: '20px', width: `${windowScreen > 576 ? '344px' : '240px'}`}}>
                            <div style={{gap: '8px', display:'flex', flexDirection: 'column'}}>
                                <p style={{lineHeight: '20px', color: '#94A3BD'}}>Метка в timetask для новых событий из Google Календарь</p>
                                <div style={{maxWidth: '240px'}}>
                                        <DefaultInput inputType="text" inputValue={gCalendarTag} placeHolder="GCalendar" onChangeFunc={updateGCalendarTag}/>
                                </div>
                            </div>
                            {/* <div style={{gap: '8px', display:'flex', flexDirection: 'column'}}>
                                <p style={{lineHeight: '20px', color: '#94A3BD'}}>Длительность события Google Календаря для новых задач</p>
                                <div style={{maxWidth: '240px'}}>
                                    <InputSelect title={optionArr[eventDuration - 1]} isActiveChildren={dropDownDuration} onClickFunc={() => {setDropDownDuration(!dropDownDuration)
                                        setDropDownExecution(false)}}> 
                                        <DropDown defaultValue={eventDuration - 1} 
                                            optionArr={optionArr} 
                                            onClickFunc={updateEventDuration} 
                                            isActive={dropDownDuration}
                                            updateIsActive={setDropDownDuration} />
                                    </InputSelect>
                                </div>
                            </div> */}
                            <div style={{gap: '8px', display:'flex', flexDirection: 'column'}}>
                                <p style={{lineHeight: '20px', color: '#94A3BD'}}>Выполненные задачи в timetask</p>
                                <div style={{maxWidth: '240px'}}>
                                    <InputSelect title={optionArrAction[executionAction - 1]} isActiveChildren={dropDownExecution} onClickFunc={() => {setDropDownExecution(!dropDownExecution)
                                        setDropDownDuration(false)}}> 
                                        <DropDown defaultValue={executionAction - 1} 
                                            optionArr={optionArrAction}
                                            onClickFunc={updateExecutionAction}
                                            isActive={dropDownExecution}
                                            updateIsActive={setDropDownExecution}/>
                                    </InputSelect>
                                </div>
                            </div>
                        </div>        
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '8px'}}>
                        <Button btnType="default" disabled={loaderSaving} onClickFunc={() => setModalShow(false)} title="Отмена" />
                        <Button btnType="filled" loading={loaderSaving} disabled={saveBtnDisable} onClickFunc={() => saveUserSettings()} title="Cохранить" />
                    </div>
            </ModalWindow>}

            <div className="integrations">
                <ul className="integrations__list">
                    <li className="integrations__list-item">
                        <h3 className="integrations__list-item__heading">Интеграция с Telegram-bot</h3>
                        <p className="integrations__list-item__description">Будут приходить напоминания в 
                        telegram-bot о запланированных задачах, мероприятиях и встречах.
                           {windowScreen > 576 ? null : <><br /><br /></>} Так ты никогда не пропустишь то, что запланировал на день.</p>
                        {
                            settingsUser.isTgBotIntegrated ?   
                            <Button btnType="danger" btnSize={`${windowScreen > 576 ? 'xl' : 'base'}`} title={'Отключить'} onClickFunc={() => removeIntegrationTg()} loading={loaderTg}/> :
                            <TelegramAuth />
                        }
                    </li>
                    <li className="integrations__list-item">
                        <h3 className="integrations__list-item__heading">Интеграция с Google Календарь</h3>
                        <p className="integrations__list-item__description">Все задачи, которые ты поставишь в timetask.ru, 
                        попадут в Google календарь и наоборот.</p>
                        {
                            settingsUser.isGCalendarIntegrated ? 
                            <div className="integrations__isIntegratedBtns">
                                <Button btnType="danger" btnSize={`${windowScreen > 576 ? 'xl' : 'base'}`} title={'Отключить'} onClickFunc={() => removeIntegration()} loading={loaderGoogle}/>
                                <Button btnType="default" btnSize={`${windowScreen > 576 ? 'xl' : 'base'}`} title={'Настроить'} onClickFunc={() => setModalShow(true)}/>
                            </div> :
                            <div>
                                <Button btnType="filled" btnSize={`${windowScreen > 576 ? 'xl' : 'base'}`} title={'Подключить'} onClickFunc={() => GetUrlGoogle()} loading={loaderGoogle}/>
                            </div>
                        }
                    </li>
                </ul>
            </div>
        </>
    )
}