import React, {useContext, useEffect, useRef} from "react";
import { modalWindowContext } from "../Context/modalWindowContext";
import Button from "../UIcomponents/Button/Button";
import { ModalCloseWarning } from "../Types/Types";


const ModalWarning: React.FC<{modalData: ModalCloseWarning}> = ({modalData}) => {
    const { data } = modalData
    const modalRef = useRef<HTMLDivElement>(null)
    const [modalShow] = useContext(modalWindowContext);

    useEffect(() => {
        if (modalShow) {
            modalRef.current?.focus()
        }
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Tab') {
                e.preventDefault()
                if (e.shiftKey) { 
                    e.preventDefault()
                }
            }
            if (e.key === 'Enter') {
                e.preventDefault()
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [modalShow])
    
    if (!modalShow) {
       return null
    }

    return (
        <div id="openModal" className={ modalShow ? "modalCloseWarning" : 'hide'} role="dialog" aria-modal="true" aria-hidden={!modalShow}>
        <div className="modalCloseWarningCloseWarning__dialog" ref={modalRef} tabIndex={99}>
            <div className="modalCloseWarning__content">
                <div className="modalCloseWarning__header">
                    <p className="modalCloseWarning__title">{data.title}</p>
                </div>
                <div className="modalCloseWarning__body">    
                    <p className="modalCloseWarning__body-description">{data.description}
                        {/* <span className="modalCloseWarning__body-description__bolt"> 
                            Сохранить?
                        </span> */}
                    </p>
                </div>
                <div className="modalCloseWarning__btn-group" style={{gap: '15px'}}>
                    <Button onClickFunc={() => data.buttons?.btnReject?.onClickFunc()} btnSize="base" btnType="default" title={data.buttons?.btnReject?.title ?? "Отмена"}></Button>
                    <Button onClickFunc={() => data.buttons?.btnAccept?.onClickFunc()} btnSize="base" btnType="filled" title={data.buttons?.btnAccept?.title ?? "Удалить"}></Button>
                </div>
            </div>
        </div>
    </div>
    )
}

export default ModalWarning;