import React, { useState, useRef, useEffect } from "react";
import { regExpGroups, regExpNoDate } from "./data/regularExpForTime";
// import MainTagsApp from "../DropDownMenu/SelectTags/MainTagsApp";
import useSetDataRecognition from "./data/useSetDataRecognition";

export interface SmartRecognitionProps {
    setDate: Function,
    setTime: Function,
    setTaskHours: Function,
    setTaskMinutes: Function,
    setTitle: Function,
    setPriority: Function,
    setAutoFocus: Function,
    checkLengthOfTitle: Function,
    updateTagList: Function,
    tagList: string[],
    setTagList: Function,
    removeTagFromTagList: Function,
    arrMatching: string[],
    setArrMatching: Function,
    priority: number,
    selectedValues: {
        priority: number | null,
        time: string | null,
        date: string | null ,
        timeToTaskHours: string | null,
        timeToTaskMinutes: string | null
    },
    enterHandler: Function,
    datePayload: Date
}

export default function SmartRecognition({setTime, setDate, setTaskHours, setTaskMinutes, setPriority, selectedValues, enterHandler, datePayload, checkLengthOfTitle, setAutoFocus, updateTagList, tagList, removeTagFromTagList, setTitle, priority} : SmartRecognitionProps) {
    const [content, setContent] = useState<string>('');
    const refSmartRecognition = useRef<(HTMLDivElement)>(null)
    const [spanElements, setSpanElements] = useState<Element[]>([])
    const { setDataRecognitions } = useSetDataRecognition(spanElements, setPriority, setDate, setTime, setTaskHours, setTaskMinutes, selectedValues, datePayload)

    const setSpanTracking = (span: HTMLSpanElement): void => { //tracking span 
        span.setAttribute('data-tracking', 'true')
        span.setAttribute('class', 'MatchedText')
        span.addEventListener('click', removeContentEditable)
    }

    const setSpanUntracking = (span: HTMLSpanElement): void => { //untracking span                
        span.setAttribute('data-tracking', 'false')
        span.removeAttribute('class')
        span.removeEventListener('click', removeContentEditable)
        setSpanElements(prevState => [...prevState])
    }
    
    const addSpanContainer = (content: string, groupName: string): HTMLSpanElement => { //surround text into span
        const spanContainer = document.createElement('span')
        spanContainer.textContent = `${content}`
        spanContainer.setAttribute('data-match-id', content)
        spanContainer.setAttribute('data-match-group', groupName)
        spanContainer.setAttribute('data-tracking', 'true')
        spanContainer.addEventListener('click', removeContentEditable)
        spanContainer.className = 'MatchedText'
        return spanContainer
    }

    const handleInput = () => { //input text handler
        if (refSmartRecognition.current) {
            const originalText = refSmartRecognition.current.innerText.replace(/&nbsp;/gi, ' ');
            setContent(originalText);
            // setTitle(cleaningUpTitle())            
        }
    };
    
    

    const cleaningUpTitle = (): string => { //cleaning entered text before saving
        const nodes = refSmartRecognition.current?.childNodes;
        const textNodes: string[] = []
        nodes?.forEach((el: ChildNode) => {
            if (el.nodeType === 1) {
                const sp: Element = el as HTMLSpanElement
                if (sp.getAttribute('data-tracking') === 'false') {
                    textNodes.push(el.textContent?.trim() ?? " ")
                }
            }          
            if (el.nodeName === '#text' && el.textContent !== "") {
                textNodes.push(el.textContent?.trim() ?? " ")
            }
        })
        const finallyText = textNodes.join(' ').replace('  ', ' ').replace(/&nbsp;/gi, ' ')
        return finallyText
    }

    const checkDublicateSpans = (groupName: keyof typeof regExpGroups): void => { //checking dublicate spans, last right element - is first
        const dublicateSpans = refSmartRecognition.current?.querySelectorAll<HTMLSpanElement>(`span[data-match-group="${groupName}"`) ?? []
        if (dublicateSpans.length > 1) {
            dublicateSpans.forEach((span, index) => {
                if (index < dublicateSpans.length - 1) {
                    setSpanUntracking(span)
                } else if (index === dublicateSpans.length - 1){
                    setSpanTracking(span)
                }
            })
        }
    }

    useEffect(()=> { //remove span NoDate if entered time to task
        const hasTime = spanElements.findIndex(el => el.getAttribute('data-tracking') === 'true' && el.getAttribute('data-match-group') === 'TaskTime')
        const hasNoDate = spanElements.findIndex(el => el.textContent?.match(regExpNoDate))        
        if (hasTime !== -1 && hasNoDate !== -1) {
            if (hasTime > hasNoDate) {
                setSpanUntracking(spanElements[hasNoDate] as HTMLSpanElement)
            } else if (hasNoDate > hasTime) {
                setSpanUntracking(spanElements[hasTime] as HTMLSpanElement)
            }
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */          
    }, [spanElements])

    const moveCaretToEnd = (htmlElement: HTMLDivElement | HTMLSpanElement): void => { //moving caret to div block end      
        const range = document.createRange();
        range.selectNodeContents(htmlElement);
        range.collapse(false);
        const selection = window.getSelection();
        selection?.removeAllRanges();
        selection?.addRange(range);
        htmlElement.focus();
        range.detach();
    }

    const removeContentEditable = (e: MouseEvent): void => { //removing span surrounds
        e.stopPropagation()                
        const value = e.target as HTMLSpanElement        
        const span = refSmartRecognition.current?.querySelectorAll(`span[data-match-group="${value.attributes[1].value}"]`) ?? [] 
        span?.forEach(el => {            
            if (el.getAttribute('data-tracking') === 'true') {
                setSpanUntracking(el as HTMLSpanElement)
                const nextSpanIndex = Array.from(span).indexOf(el);
                if (nextSpanIndex > 0 && nextSpanIndex < span.length) {
                    setSpanTracking(span[nextSpanIndex - 1] as HTMLSpanElement)
                    moveCaretToEnd(span[nextSpanIndex - 1] as HTMLSpanElement)
                }
            }
        })
        setTitle(cleaningUpTitle())
    }

    useEffect(() => {  //removing span if entered text not match by id
        spanElements?.forEach((span) => {
            if (span.nodeName === 'SPAN' && span.contains(document?.getSelection()?.anchorNode ?? null)) {
                const textNode = document.createTextNode(span.textContent || '')
                const spanRange = new Range()
                if (span.textContent !== span.getAttribute('data-match-id')) {
                    setSpanUntracking(span as HTMLSpanElement)
                    spanRange.selectNode(span)
                    spanRange.deleteContents()
                    spanRange.insertNode(textNode)
                    spanRange.collapse(false);
                    const selection = window.getSelection();
                    selection?.removeAllRanges();
                    selection?.addRange(spanRange);
                    spanRange.detach();
                } else {
                    setSpanTracking(span as HTMLSpanElement)
                }
            }
        })
            /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [refSmartRecognition.current?.textContent])

    useEffect(() => { //cleaning document from empty nodes
        refSmartRecognition.current?.childNodes.forEach(el => {
            if ((el.nodeName === '#text' && el.nodeValue === '') || (el.nodeName === 'BR')) {
                el.remove()
            }
        })
    })

    useEffect(() => { //tracking entered text by regExp       
        const divChilds = refSmartRecognition.current?.childNodes        
        const nodeRange = new Range();
        divChilds?.forEach((Node) => {
            if (Node.nodeName === '#text' && Node.nodeValue !== ' ') {
                const textNode = Node.textContent ?? ''
                for (const [groupName, regexpArr] of Object.entries(regExpGroups)) {
                    for (const elRegExp of regexpArr) {
                        const matches = [...textNode.matchAll(elRegExp)]
                        if (matches.length > 0) {
                            const lastMatch = matches[matches.length - 1]
                            const newSpan = addSpanContainer(lastMatch[0], groupName)                        
                            nodeRange.setStart(Node, lastMatch?.index!)
                            nodeRange.setEnd(Node, lastMatch[0].length + lastMatch.index!)
                            nodeRange.surroundContents(newSpan)
                            Node.textContent?.trim()
                            moveCaretToEnd(newSpan)
                            setSpanElements(prevState => [...prevState, newSpan])                            
                            checkDublicateSpans(groupName as keyof typeof regExpGroups)
                        }
                    }
                }
            }
        })
        setTitle(cleaningUpTitle())
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [content])


    useEffect(() => { //calling custom hook from changing an array of span elements        
        setDataRecognitions()
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [spanElements])

    // useEffect(() => { //unconcatenate time + date || hours + minutes
    //     let hasDate:boolean = spanElements.some(el => el.getAttribute('data-match-group') === 'Date');
    //     let hasTaskTime:boolean = spanElements.some(el => el.getAttribute('data-match-group') === 'TaskTime');
    //     let hasFullTime:boolean = spanElements.some(el => el.getAttribute('data-match-group') === 'FullDate');
    //     if ((hasDate || hasTaskTime) && hasFullTime) {
    //         const untrackingUnion = refSmartRecognition.current?.querySelector('span[data-match-group = FullDate]')
    //         setSpanUntracking(untrackingUnion as HTMLSpanElement)
    //         if ((hasDate || hasTaskTime) && untrackingUnion) {
    //             if (hasDate) {
    //                 const spanDate = spanElements.find(el => el.getAttribute('data-match-group') === "Date")
    //                 if (spanDate?.textContent?.match(regExpNoDate)) {
    //                     return
    //                 }
    //                 for (const regExp of regExpGroups.TaskTime) {
    //                     const match = untrackingUnion?.textContent?.match(regExp)
    //                     if (match) {
    //                         untrackingUnion.textContent = untrackingUnion.textContent?.replace(`${match[0]}`, '').trim() ?? ' '
    //                         untrackingUnion.setAttribute('data-match-group', 'Date')
    //                         untrackingUnion.setAttribute('data-match-id', `${untrackingUnion.textContent}`)
    //                         const textContent = `${spanDate?.textContent} ${match[0]}`;
    //                         const concatSpan = addSpanContainer(textContent, 'FullDate')
    //                         spanDate?.remove()
    //                         setSpanElements(prevState => [...prevState.filter(el => el !== spanDate), concatSpan])  
    //                         refSmartRecognition.current?.appendChild(concatSpan)
    //                         moveCaretToEnd(concatSpan as HTMLSpanElement)
    //                     }
    //                 }
    //             } if (hasTaskTime) {
    //                 const spanDate = spanElements.find(el => el.getAttribute('data-match-group') === "TaskTime")
    //                 for (const regExp of regExpGroups.Date) {
    //                     const match = untrackingUnion?.textContent?.match(regExp)
    //                     if (match) {
    //                         untrackingUnion.textContent = untrackingUnion.textContent?.replace(`${match[0]}`, '').trim() ?? ' '
    //                         untrackingUnion.setAttribute('data-match-group', 'TaskTime')
    //                         untrackingUnion.setAttribute('data-match-id', `${untrackingUnion.textContent}`)
    //                         const textContent = `${spanDate?.textContent} ${match[0]}`;
    //                         const concatSpan = addSpanContainer(textContent, 'FullDate')
    //                         spanDate?.remove()
    //                         setSpanElements(prevState => [...prevState.filter(el => el !== spanDate), concatSpan])  
    //                         refSmartRecognition.current?.appendChild(concatSpan)
    //                         moveCaretToEnd(concatSpan as HTMLSpanElement)
    //                     }
    //                 }
    //             }
    //         }              
    //     }
    //         /* eslint-disable-next-line react-hooks/exhaustive-deps */
    // }, [spanElements])

    // useEffect(() => { //concatenate time + date || hours + minutes
    //     const concatArrFullDate: Element[] = []
    //     const concatArrFullTime: Element[] = []
    //     spanElements.forEach(el => {
    //         if (el.getAttribute('data-tracking') === 'true') {
    //             if (el.getAttribute('data-match-group') === "Date") {
    //                 let getMatch = el.textContent
    //                 if (getMatch?.match(regExpNoDate)) {
    //                     return
    //                 } else {
    //                     concatArrFullDate.push(el)
    //                 }
    //             } else if ((el.getAttribute('data-match-group') === "TaskTime")) {
    //                 concatArrFullDate.push(el)
    //             } else if (((el.getAttribute('data-match-group') === "TimeHours") || (el.getAttribute('data-match-group') === "TimeMinutes")) && (el.getAttribute('data-tracking') === 'true')) {
    //                 concatArrFullTime.push(el)
    //             } else {
    //                 return
    //             }
    //         }
    //     })

    //     if (concatArrFullDate.length === 2) {
    //         const textContent = `${concatArrFullDate[0].textContent} ${concatArrFullDate[1].textContent}`
    //                 const concatSpan = addSpanContainer(textContent, 'FullDate')
    //                 concatArrFullDate.forEach(el => {
    //                     setSpanElements(prevState => prevState.filter(prevEl => prevEl !== el))                
    //                     el.remove()
    //                 })
    //                 setSpanElements(prevState => [...prevState, concatSpan])  
    //                 refSmartRecognition.current?.appendChild(concatSpan)
    //                 moveCaretToEnd(concatSpan as HTMLSpanElement)
    //                 concatArrFullDate.length = 0
    //     }

    //     if (concatArrFullTime.length === 2) {
    //         const textContent = `${concatArrFullTime[0].textContent} ${concatArrFullTime[1].textContent}`
    //         const concatSpan = addSpanContainer(textContent, 'FullTime')
    //         concatArrFullTime.forEach(el => {
    //             setSpanElements(prevState => prevState.filter(prevEl => prevEl !== el))                
    //             el.remove()
    //         })
    //         setSpanElements(prevState => [...prevState, concatSpan])  
    //         refSmartRecognition.current?.appendChild(concatSpan)
    //         moveCaretToEnd(concatSpan as HTMLSpanElement)
    //         concatArrFullTime.length = 0
    //     }
    //         /* eslint-disable-next-line react-hooks/exhaustive-deps */        
    // }, [spanElements])    
    
    useEffect(() => { //replacement span node if its contents are not equal to its id with text node
        const currentSpans = refSmartRecognition.current?.querySelectorAll('span');
        const range = new Range();
        currentSpans?.forEach((span: Element) => {
            if (span.contains(document?.getSelection()?.anchorNode ?? null)){
                if (span.textContent !== span.getAttribute('data-match-id')) {
                    const textNode = document.createTextNode(`${span.textContent}`)
                    refSmartRecognition.current?.replaceChild(textNode, span)
                    const selection = window.getSelection();
                    range.setStart(textNode, textNode.length);
                    range.collapse(true);
                    selection?.removeAllRanges();
                    selection?.addRange(range);
                }
            }
        })
    })

    const keyHandler = (e: KeyboardEvent): void  => { //key press handler
        const currentSpans = refSmartRecognition.current?.querySelectorAll('span');
        currentSpans?.forEach((span: Element) => {
            if (span.contains(document?.getSelection()?.anchorNode ?? null)){
                if (e.code === 'Backspace') {
                    if (!e.repeat) {
                        if (span.getAttribute('data-tracking') === 'true') {
                            e.preventDefault()
                            setSpanUntracking(span as HTMLSpanElement)
                        }

                        const twinSpans = refSmartRecognition.current?.querySelectorAll(`span[data-match-group="${span.getAttribute('data-match-group')}"`) ?? []                        
                        
                        if (twinSpans?.length > 1) {
                            const nextSpanIndex = Array.from(twinSpans).indexOf(span);
                            if (nextSpanIndex > 0 && nextSpanIndex < twinSpans.length) {
                                setSpanTracking(twinSpans[nextSpanIndex - 1] as HTMLSpanElement)
                                moveCaretToEnd(twinSpans[nextSpanIndex - 1] as HTMLSpanElement)
                            }
                        }
                        setTitle(cleaningUpTitle())                        
                    }
                } else if (e.code === 'Space') {
                    span.setAttribute("contentEditable", "false")
                    moveCaretToEnd(refSmartRecognition.current as HTMLDivElement);
                    setTimeout(() => {
                        span.setAttribute("contentEditable", "true")
                    }, 0);
                } if (e.code === 'Enter') {
                    e.preventDefault()
                }
            }
        })
        if (e.code === 'Enter') {
            e.preventDefault()
            enterHandler(e)
        }
        if (e.code === 'Delete') {            
            const selection = window.getSelection();
            if (!selection?.rangeCount) return;
            const range = selection?.getRangeAt(0);
            const beforeNode = range.startContainer;
            if (beforeNode.nodeType === Node.TEXT_NODE) {
                const parentElement = beforeNode;
                if (parentElement && parentElement.nextSibling && parentElement.nextSibling?.nodeName === 'SPAN') {
                    const span = parentElement.nextSibling;
                    setSpanUntracking(span as HTMLSpanElement)
                }
            }
        }
    }    

    return (
        <div>
            <div id="smartRecognition"
                contentEditable
                data-placeholder="Название задачи"
                ref={refSmartRecognition}
                spellCheck={false}
                className="add-task__input add-task__input-title"
                onInput={handleInput}
                onPaste={(e) => e.preventDefault()}
                onKeyDown={(e) => keyHandler(e as any)}
                />
            {/* {
                isTagsListOpen &&
                    <div style={{position: 'absolute', zIndex: 1, width: '100%', maxWidth: '697px', height: '130px', overflow: "hidden", overflowY: 'hidden', backgroundColor: '#FFF', border: '1px solid #E8E8E8', borderRadius: '10px', boxShadow: '0px 0px 25px 0px #23236026'}}>
                        <MainTagsApp
                            updateTagList={updateTagList}
                            tagList={tagList}
                            smartRecognition={true}
                            addTagSpan={addTagSpan}
                        ></MainTagsApp>
                    </div>
            } */}
        </div>
    )
}

