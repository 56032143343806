import React, { useEffect } from "react";
import Loader from "../../Loader/Lodaer";
import request from "../../Requests/requests";
import './SuccessGoogle.css'
import { ReactComponent as SuccessIcon } from "./static/CheckCircle.svg";
import { ReactComponent as FailureIcon } from "./static/alert.svg";
import Button from "../../UIcomponents/Button/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loadUserSettings } from "../../store/settingsReducer";


export default function SuccessGoogle() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const receivedUrl = new URL(window.location.href)
    const stylesButton = {
        marginTop: '30px'
    }
    const stylesTitle = {
        fontSize: '15px',
        lineHeight: '20px'
    }

    const redirectTo = () => {
        navigate('/integrations')
    }

    const getUserSettings = () => {
        request.get('/Settings/', {
            params: { UserId: localStorage.getItem('token') }
          })
            .then((response) => dispatch(loadUserSettings(response.data)))
    }

    const callbackGoogle = (code?: string | null, error?: string | null, state?: string | null) => {
        request.post('/GoogleCalendar/Callback', {
            userId: localStorage.getItem('token'),
            code: code,
            error: error,
            state: state
        }).then((response) => response.status === 200 && getUserSettings())
    }

    useEffect(() => {
        if (receivedUrl) {
            callbackGoogle(receivedUrl.searchParams.get('code'), 
                            receivedUrl.searchParams.get('error'), 
                            receivedUrl.searchParams.get('state'))
        }
         /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [])

    if (!receivedUrl) {
        return ( 
            <Loader />
        )
    } else {
        if (receivedUrl.searchParams.get('error') !== null) {
            return (
                <div className="failureGoogle">
                    <div className="failureGoogle__content">
                        <div className="failureGoogle__content__row1">
                            <FailureIcon/>
                            <h1>Упс, что-то пошло не так</h1>
                        </div>
                        <p>Повторите интеграцию с <span className="google-highlight">Google Календарь</span></p>
                        <Button btnType="filled" btnSize="xl" onClickFunc={redirectTo} stylesButton={stylesButton} stylesTitle={stylesTitle} title={'Попробовать снова'}/>
                    </div>
                </div>
                
            )
        } else {
            setTimeout(() => {
                redirectTo()
            }, 2500);
            return (
                <div className="successGoogle">
                    <div className="successGoogle__content">
                        <SuccessIcon/>
                        <h1>Интеграция с <span className="google-highlight">Google Календарь</span> прошла успешно</h1>
                    </div>
                </div>
            )
        }
    }
}