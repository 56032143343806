import React, {useState, useEffect} from "react";
import request from "../Requests/requests";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
// import { CheckTimeZone } from "./CheckTimeZone";
import { sendDataLayer } from "../utils/sendDataLayer";
import Cookies from 'universal-cookie';
import Validation from "../UIcomponents/Validation/Validation";
import { useDispatch } from "react-redux";
import { loadUserSettings } from "../store/settingsReducer";
import useTimeZone from "../customHooks/useTimeZone";
import AuthVariants from "../Pages/Login/AuthVariants/AuthVariants";
import FailureAuth from "../Pages/Login/AuthVariants/AuthStatusPage/FailureAuth";
import Loader from "../Loader/Lodaer";

export default function AuthUser({setIsAuth}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [isAuthError, setIsAuthError] = useState(false);
  const [errorAuth, setErrorAuth] = useState({
    emailErr: '',
    typeAuth: ''
  })
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isLoadingAuthSocial, setIsLoadingAuthSocial] = useState(false)
  const focusEmail = document.getElementById('email')
  const { getCurrentTimeZone } = useTimeZone()
  const focusPassword = document.getElementById('password')
  const cookies = new Cookies();
  let arrErrors = new Array([]);
  const [dataErrors, setDataErrors] = useState([]);
  
  const hadnleLogin = (res) => {
    setIsAuth(true)
    setLoading(false)
    sendDataLayer('auth_password', 'auth_password')
    localStorage.setItem('token', res.data.id);
    localStorage.setItem('authToken', res.data.token);
    localStorage.setItem('firstName', res.data.firsName);
    localStorage.setItem('lastName', res.data.lastName === null ? '' : res.data.lastName);    
    localStorage.setItem('interceptorTry', 'false');
    cookies.set('refreshToken', res.data.refreshToken, {maxAge: 24*60*60*7})
    request.get('/Settings/', {
      params: {UserId: res.data.id }
    }).then(response => {
        dispatch(loadUserSettings(response.data))
        localStorage.setItem('isTimeTracking', response.data.isTimeTracking);
        navigate('/', { require: true});
      });
  }

  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate('/', { replace: true })
    }
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  /* eslint-disable-next-line */
  let emailReg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
  /* eslint-disable-next-line */
  useEffect(() => {
    if ((email !== '') && (password !== '')) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  });
  
  useEffect(() => {
    const AuthChanel = new BroadcastChannel('Auth')
    AuthChanel.onmessage = (e) => {
        const payload = {
          code: e.data.payload,
          timeZoneId: getCurrentTimeZone()
        }
        request.post(`/auth/${e.data.type}/callback`, payload)
        .then((res) => {
          setIsLoadingAuthSocial(true)
          hadnleLogin(res)
        })
        .catch((error) => {
          setIsLoadingAuthSocial(false)
          const emailPattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g
          const mail = `${error.response.data}`
          const result = mail.match(emailPattern) ?? ''
          setErrorAuth({
            emailErr: `${result}`,
            typeAuth: `${e.data.type}`
          })
          setIsAuthError(true)
        })
    
    }

    return () => {
      AuthChanel.close();
    }
    // eslint-disable-next-line
  }, [])
  
  function focusingPassword(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      focusPassword.focus()
    }     
  }
  
  function handleSubmit(e) {
    e.preventDefault();
    if (emailReg.test(email) === true) {
      setLoading(true)
      setDataErrors([])
      request.post('/Account/Login', {  
            email: email,
            password: password})
            .then(res => {
              hadnleLogin(res)
            })
            .catch((error) => {
              setLoading(false)
              let arrGetError = Object.entries(error.response.data.errors)
                arrGetError.forEach(([key, value]) => {
                  value.map(err => arrErrors.push({key, err}))
                  setDataErrors(arrErrors)
                })
            })
            // .catch((error) => {
            //   setLoading(false)
            //   if(error.response.data.errors.Email != null){
            //     let lengthEmail = error.response.data.errors.Email.length;
            //     let errors = "";
            //     for(let i = 0; i < lengthEmail; i++){
            //         errors += "* " + error.response.data.errors.Email[i] + "\r\n";
            //     }
            //     setErrorEmail(errors)
            //     setIsHiddenEmailErr(false);
            //   }else{
            //     setErrorEmail("");
            //     setIsHiddenEmailErr(true);
            //   }
  
            //   if(error.response.data.errors.Password != null){
            //     let lengthPassword = error.response.data.errors.Password.length;
            //     let errors = "";
            //     for(let i = 0; i < lengthPassword; i++ ){
            //       errors += "* " + error.response.data.errors.Password[i] + "\r\n";
            //     }
            //     setErrorPassword(errors)
            //     setIsHiddenPassErr(false);
            //   }else{
            //     setErrorPassword("");
            //     setIsHiddenPassErr(true);
            //   }
              
            // })
          }
    else {
      arrErrors.push({key: 'Email', err: 'некорректный email'});
      setDataErrors(arrErrors)
      focusEmail.focus()
    }
  }
            return (
              <>
                {
                  isAuthError ? <FailureAuth emailErr={errorAuth.emailErr} 
                                              typeAuth={errorAuth.typeAuth} 
                                              setIsAuthError={setIsAuthError}/> : 
                  <div className="register-form__page">
                    {
                      isLoadingAuthSocial ? <Loader /> :
                      <div className="register-form__container">
                      <h2 className="register-form__heading">Вход в аккаунт</h2>
                        <form onSubmit={handleSubmit} className='register-form__form'>
                          <div style={{width: "100%"}}>
                            <input  type='text' 
                                    id='email' 
                                    name='email' 
                                    value={email}
                                    onKeyDown={(e) => focusingPassword(e) } 
                                    onChange={e => setEmail(e.target.value)} 
                                    placeholder='E-mail' 
                                    className="register-form__input">
                            </input>
                            <Validation data={dataErrors} keyItem={'Email'}/>
                          </div>
                          <div style={{width: "100%"}}>
                            <input  type='password' 
                                    id='password' 
                                    name='password' 
                                    value={password} 
                                    onChange={e => setPassword(e.target.value)} 
                                    placeholder='Пароль' 
                                    className="register-form__input">
                            </input>
                            <Validation data={dataErrors} keyItem={'Password'}/>
                          </div>
                            <button onClick={() => handleSubmit} disabled={isDisabled} className="login-form__btn">
                            {loading ? <div className="centered-btn-spinner"><div class="btn-lds-ring"><div></div><div></div><div></div><div></div></div></div> : 'Войти'}
                            </button>
                            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                              <p className="login-description">
                                Забыли пароль? <Link to="/forgot_password">Восстановить</Link>
                              </p>
                              <p className="login-description">
                                Еще нет аккаунта? <Link to="/register">Зарегистрироваться</Link>
                              </p>
                            </div>
                        </form>
                        <AuthVariants />
                      </div>
                    }
                  </div>
                }
              </>               
            )
}