import React, { useState, useRef, useEffect } from "react";
import '../textEditor/quill.bubble.css';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { addTodo } from "../asyncActions/addTodos";
import { getTimeAllTodos } from '../asyncActions/getTimeAllTodos';
import { useLocation } from "react-router-dom";
import { useOutsideClick } from "../customHooks/useOutsideClick";
import { ReactComponent as DateCalendar } from "../icons/svg/ModalChangingTask/calendar.svg";
import { ReactComponent as TimeSvg } from "../icons/svg/timetotasklogo.svg";
import { ReactComponent as RemoveTag } from "../icons/svg/remove-tag-cross.svg";
import DDSelectDate from "../DropDownMenu/SelectDateCalendar/DDSelectDate";
import DDSelectPriority from "../DropDownMenu/SelectPriority/DDSelectPriority";
import DDSelectTags from "../DropDownMenu/SelectTags/DDSelectTags";
import DDSelectTimeToTask from "../DropDownMenu/SelectTimeTotask/DDSelecTimeToTask";
import ConverterUTC from "../ConverterUTC/ConverterUTC";
import { useConnectionHubContext } from "../Context/connectionHubContext";
import DDSelectRepeatTemplate from "../DropDownMenu/SelectRepeatTemplate/DDSelectRepeatTemplate";
import { addRepeatTemplate } from "../asyncActions/RepeatTemplatesService/addRepeatTemplate";
import SmartRecognition from "../SmartTextRecognition/SmartTextRecognition";
import { modalWindowContext } from "../Context/modalWindowContext";
import ModalWarning from "../ModalWindow/ModalWindowSaveChanges";


export default function TodosAddApp({ autofocus, datePayload, dateFromCalendarPage, repeatTemplate=false, ...props }) {
    const dispatch = useDispatch();
    const location = useLocation();
    const settingUser = useSelector(state => state.settings.settings)
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState();
    const [date, setDate] = useState(datePayload ? datePayload.toLocaleDateString() : '');
    const [time, setTime] = useState();
    const [tagList, setTagList] = useState([]);
    const [priority, setPriority] = useState(3);
    const [timeToTaskHours, setTimeToTaskHours] = useState('');
    const [timeToTaskMinutes, setTimeToTaskMinutes] = useState('');
    const [currentLenghtOfTitle, setCurrentLenghtOfTitle] = useState();
    const [templatePeriod, setTemplatePeriod] = useState(null);
    const [isActiveCounter, setIsActiveCounter] = useState(false);
    const [alertNullPeriod, setAlertNullPeriod] = useState(false);
    const [alertNullPeriodIcon, setAlertNullPeriodIcon] = useState(false);
    const maxLenghtOfTitle = 150;
    const [diasbledBtn, setDisabledBtn] = useState(true)
    const classPriorityArray = ['priority-span-addTask-1', 'priority-span-addTask-2', 'priority-span-addTask-3', 'priority-span-addTask-0']
    const dateToday = ConverterUTC({ method: 'object', settingsUser: settingUser });
    const dateTomorrow = new Date()
    dateTomorrow.setDate(dateTomorrow.getDate() + 1)
    const dateNormalize = dateToday.toLocaleDateString();
    const [menuActivDatePicker, setMenuActivDatePicker] = useState(false);
    const [menuActivPriorityPicker, setMenuActivPriorityPicker] = useState(false);
    const [menuActivTagsPicker, setMenuActivTagsPicker] = useState(false);
    const [menuActivTimePicker, setMenuActivTimePicker] = useState(false);
    const [isEmptyDateValue, setIsEmptyDateValue] = useState(true);
    const [isEmptyTimeValue, setIsEmptyTimeValue] = useState(true);
    const [scrolledY, setScrolledY] = useState(0);
    const [warningWin, setWarningWin] = useState(false)
    const connectionHub = useConnectionHubContext()
    const refCloseDatePicker = useRef();
    useOutsideClick(refCloseDatePicker, () => setMenuActivDatePicker(false), menuActivDatePicker);
    const refCloseTimePicker = useRef();
    useOutsideClick(refCloseTimePicker, () => setMenuActivTimePicker(false), menuActivTimePicker);
    const refClosePriorityPicker = useRef();
    useOutsideClick(refClosePriorityPicker, () => setMenuActivPriorityPicker(false), menuActivPriorityPicker);
    const refCloseTagsPicker = useRef();
    useOutsideClick(refCloseTagsPicker, () => setMenuActivTagsPicker(false), menuActivTagsPicker);
    const refTemplateDiv = useRef();
    const [selectedValues, setSelectedValues] = useState({
        priority: null,
        time: null,
        date: null,
        timeToTaskHours: null,
        timeToTaskMinutes: null
    })
    
    const daysOfWeek = [
        'Воскресенье',
        'Понедельник',
        'Вторник',
        'Среда',
        'Четверг',
        'Пятница',
        'Суббота'
    ]

    const updateDataDate = (payload) => {
        setSelectedValues(prevState => ({...prevState, date: payload.date}))
        setDate(payload.date)
    }

    const updateTagList = (payload) => {
        setTagList([...tagList, payload])
      }
    const updateDataTimeToTaskHours = (payload) => {
        setSelectedValues(prevState => ({...prevState, timeToTaskHours: payload.timeToTaskHours}))
        setTimeToTaskHours(payload.timeToTaskHours)
      }
    const updateDataTimeToTaskMinutes = (payload) => {
        setSelectedValues(prevState => ({...prevState, timeToTaskMinutes: payload.timeToTaskMinutes}))
        setTimeToTaskMinutes(payload.timeToTaskMinutes)
      }
    
      const updateDataTime = (payload) => {
        setSelectedValues(prevState => ({...prevState, time: payload.time}))
        setTime(payload.time)
      }
      
      const updateDataPriority = (payload) => {
        setPriority(payload.priority)
        setSelectedValues(prevState => ({...prevState, priority: payload.priority}))
        }
        
        
      function selectedTimeToTask() {
        if ((timeToTaskHours === undefined) && (timeToTaskMinutes === undefined)) {
          return ('');
        } else if (((timeToTaskHours === undefined) || (timeToTaskHours === '')) && (timeToTaskMinutes !== undefined) && (timeToTaskMinutes !== '')) {
          return (timeToTaskMinutes + ' мин');
        } else if (((timeToTaskMinutes === undefined) || (timeToTaskMinutes === '')) && (timeToTaskHours !== undefined) && (timeToTaskHours !== '')) {
          return (timeToTaskHours + ' ч');
        } else if ((timeToTaskHours === '') && (timeToTaskMinutes === '')) {
          return ('');
        } else if ((timeToTaskHours === undefined) && (timeToTaskMinutes === undefined)) {
          return ('');
        } else if ((timeToTaskHours === undefined) && (timeToTaskMinutes === '')) {
          return ('');
        } else if ((timeToTaskHours === '') && (timeToTaskMinutes === undefined)) {
          return ('');
        } else {
          return (timeToTaskHours + ' ч ' + timeToTaskMinutes + ' мин');
        }
      }
    
    useEffect(() => {
        setDate(datePayload ? datePayload.toLocaleDateString() : '')
    }, [datePayload])

    useEffect(() => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = title;

        const spanElements = tempElement.querySelectorAll('span');

        spanElements.forEach((spanElement) => {
            spanElement.parentNode.removeChild(spanElement);
        });
        setTitle(tempElement.innerHTML.trim())
    }, [title])

    const updateTaskList = () => {
        connectionHub.updateHub.invoke("Send", localStorage.getItem('token'))
            .catch(err => console.error(err));
    }
    useEffect(() => {
        if ((date || time !== undefined) || (date === '')) {
            setIsEmptyDateValue(false)
        } else {
            setIsEmptyDateValue(true)
        }
    }, [date, time])

    useEffect(() => {
        if ((timeToTaskHours || timeToTaskMinutes !== undefined) && (timeToTaskHours || timeToTaskMinutes !== '')) {
            setIsEmptyTimeValue(false)
        } else {
            setIsEmptyTimeValue(true)
        }
    }, [timeToTaskHours, timeToTaskMinutes])

    useEffect(() => {
        if (title === '') {
            setDisabledBtn(true)
        } else {
            setDisabledBtn(false)
        }
    }, [title])
    // const unDisableButtonToPushTask = () => {
    //     if (title.replace(/(<([^>]+)>)/gi, '') === '') {
    //         setDisabledBtn(true)
    //     } else {
    //         setDisabledBtn(false)
    //     }
    // }

    useEffect(() => {
        const handleScrolledY = () => {
            setScrolledY(window.scrollY);
        };

        window.addEventListener('scroll', handleScrolledY);

        return () => {
            window.removeEventListener('scroll', handleScrolledY);
        };
    }, [])

    useEffect(() => {
        if (autofocus) {
            document.getElementById('smartRecognition').focus()
        }
    }, [autofocus])

    useEffect(() => {
        if ((date || time !== undefined) || (date === '')) {
            setIsEmptyDateValue(false)
        } else {
            setIsEmptyDateValue(true)
        }
    }, [date, time])

    useEffect(() => {
        if ((timeToTaskHours || timeToTaskMinutes !== undefined) && (timeToTaskHours || timeToTaskMinutes !== '')) {
            setIsEmptyTimeValue(false)
        } else {
            setIsEmptyTimeValue(true)
        }
    }, [timeToTaskHours, timeToTaskMinutes])

    function toNormalizeDate(date) {
        if (date === '') {
            return date
        } else {
            const parts = date.split('.');
            const day = parseInt(parts[0], 10);  
            const month = parseInt(parts[1], 10) - 1; 
            const year = parseInt(parts[2], 10);
            return new Date(year, month, day);
        }
    }

    function checkValueInputDate() {
        if ((date === '') && (time !== undefined)) {
            return 'Без срока ' + time
        } else if ((date === '') && (time === undefined)) {
            return 'Без срока'
        } else if (((date !== undefined) && (date === dateNormalize)) && (time !== undefined)) {
            return 'Сегодня  ' + time
        } else if (((date !== undefined) && (date === dateTomorrow.toLocaleDateString())) && (time !== undefined)) {
            return 'Завтра  ' + time
        } else if ((((date !== undefined) && (date !== dateNormalize)) && (date !== dateTomorrow.toLocaleDateString())) && (time !== undefined)) {
            const firstDayOfWeek = new Date()
            let getfirst = new Date(firstDayOfWeek.setDate(firstDayOfWeek.getDate() - firstDayOfWeek.getDay() + 1))
            const lastDayOfWeek = new Date()
            let getlast = new Date(lastDayOfWeek.setDate(lastDayOfWeek.getDate() - lastDayOfWeek.getDay() + 7))
            if (toNormalizeDate(date) >= getfirst && toNormalizeDate(date) <= getlast) {
                return daysOfWeek[toNormalizeDate(date).getDay()] + '  ' + time
            } else {
                return date + '  ' + time
            }
        } else if (((date !== undefined) && (date === dateNormalize)) && (time === undefined)) {
            return 'Сегодня'
        } else if (((date !== undefined) && (date === dateTomorrow.toLocaleDateString())) && (time === undefined)) {
            return 'Завтра'
        } else if ((((date !== undefined) && (date !== dateNormalize)) && (date !== dateTomorrow.toLocaleDateString())) && (time === undefined)) {
            return date
        } else if ((date === undefined) && (time !== undefined) && (location.pathname === '/')) {
            return 'Сегодня  ' + time
        } else if ((date === undefined) && (time !== undefined)) {
            return time
        }
    }

    const checkLenghtOfTitle = (value, e) => {
        if (value.length < 100) {
            return setIsActiveCounter(false);
        } else if (value.length === 100) {
            return setCurrentLenghtOfTitle(value.length) & setIsActiveCounter(true);
        } else if ((value.length <= maxLenghtOfTitle)) {
            return setCurrentLenghtOfTitle(value.length);
        } else if ((value.length >= maxLenghtOfTitle && e.key !== 'Backspace')) {
            return e.preventDefault()
        }
    }

function handleSubmit(e) {
    e.preventDefault()
        const obj = {
            title: title.replace(/(<([^>]+)>)/gi, ''),
            description: description,
            date: date,
            time: time,
            priority: priority,
            hours: Number(timeToTaskHours) ?? 0,
            minutes: Number(timeToTaskMinutes) ?? 0,
            tagsId: tagList.map((tagId) => tagId.tagId),
            dateFrom: dateFromCalendarPage?.toDateString(),
            repitPeriod: templatePeriod, 
        }
        if (repeatTemplate) {
            if (templatePeriod !== null) {
                props.updateData(false)
                dispatch(addRepeatTemplate(obj))
                updateTaskList();
            } else {
                setAlertNullPeriod(true)
                setAlertNullPeriodIcon(true)
                const setNullTimeout = setTimeout(() => {
                    setAlertNullPeriod(false)
                    clearTimeout(setNullTimeout)
                }, 3500);
            }
        } else {
            props.updateData(false)
            dispatch(addTodo(obj));
            updateTaskList();
            dispatch(getTimeAllTodos());
        }
    // props.updateData(false)
}

function handleCombinationCtrlEnter(e) {
    if (e.ctrlKey && e.keyCode === 13) {
        e.preventDefault()
        if (diasbledBtn === false) {
            handleSubmit(e)
        }
    }
}

function handleSubmitOfCombination(e) {
    if (e.keyCode === 13) {
        e.preventDefault()
        if (diasbledBtn === false) {
            handleSubmit(e)
        }
    } 
}

function scrollToAlert() {
    if (refTemplateDiv.current) {
        refTemplateDiv.current.scrollIntoView({ behavior: "smooth" });
    }
}

function removeTagFromTagList(index) {
    setTagList(tagList.filter(tagRemoved => tagRemoved.tagId !== index));
}

function stopEnter(e) {
    if (e.keyCode === 13) {
        e.preventDefault()
    }
}
const closeAddApp = (e) => {
    if (e.keyCode === 27) {
        e.preventDefault()
        if (title !== '') {
            setWarningWin(true)
        } else {
            setWarningWin(false)
            props.updateData(false)
        }
    }
}

function closeWithoutSave() {
    setWarningWin(false)
    props.updateData(false)
}

function cancelWithoutSave() {
    let htmlElement = document.getElementById('smartRecognition')
    setWarningWin(false)
    const range = document.createRange();
    range.selectNodeContents(htmlElement);
    range.collapse(false);
    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);
    htmlElement.focus();
    range.detach();
}

const modalData = {
    data: {
        title: 'Удалить несохраненные данные?',
        description: 'Несохраненные изменения будут удалены.',
        buttons: {
            btnAccept: {
                title: 'Удалить',
                onClickFunc: closeWithoutSave
            },
            btnReject: {
                title: 'Отмена',
                onClickFunc: cancelWithoutSave
            }
        }
    }
}

return (
    <div className="add-task__container" onKeyDown={(e) => closeAddApp(e)}>
        {
            warningWin &&
            <modalWindowContext.Provider value={[warningWin, setWarningWin]}>
                <ModalWarning modalData={modalData} />
            </modalWindowContext.Provider>
        }
        <form onSubmit={handleSubmit} className="add-task__form">
            <div className="add-task__container__input-field">
                <SmartRecognition setDate={setDate} 
                    setTime={setTime} 
                    setPriority={setPriority}
                    setTitle={setTitle}
                    datePayload={datePayload}
                    selectedValues={selectedValues}
                    setTaskHours={setTimeToTaskHours}
                    setTaskMinutes={setTimeToTaskMinutes} 
                    checkLengthOfTitle={checkLenghtOfTitle}
                    enterHandler={handleSubmitOfCombination}
                    />

                {/* <div className="add-task__input add-task__input-title"
                    ref={refDivTitle}
                    contentEditable
                    id="smartRecognition"
                    data-placeholder="Название задачи"
                    onInput={(e) => setTitle(e.target.textContent)}
                    onPaste={(e) => e.preventDefault()}
                    onKeyDown={(e) => checkLenghtOfTitle(e)}
                /> */}
                <div contentEditable
                    id="description"
                    className="add-task__input add-task__input-description"
                    data-placeholder="Описание задачи"
                    onKeyDown={(e) => handleCombinationCtrlEnter(e)}
                    onInput={(e) => setDescription(e.target.textContent)}
                />
            </div>
            <hr className="separator-add-task" />
            {/* <div className="separator-add-task" /> */}
            <div className="add-task__change-group">
                <div ref={refCloseDatePicker}
                    className="add-task__change-group__data"
                    style={menuActivDatePicker ?
                        { border: '1px solid #5932EA' } : null
                            ||
                            isEmptyDateValue ?
                            null : { border: '1px solid #5932EA' }}
                    onClick={() => setMenuActivDatePicker(!menuActivDatePicker)}>
                    <DateCalendar className="add-task__calendar-svg"
                        style={menuActivDatePicker ?
                            { stroke: '#5932EA' } : null
                                ||
                                isEmptyDateValue ? null : { stroke: '#5932EA' }} />
                    <input
                        type="text"
                        readOnly
                        placeholder={location.pathname === '/' ? 'Сегодня' : 'Срок выполнения'}
                        className="priority__input-date"
                        style={isEmptyDateValue ? null : { color: 'rgb(89, 50, 234)' }}
                        value={checkValueInputDate()}
                        onKeyDown={(e) => stopEnter(e)} />

                </div>
                <div ref={refCloseTimePicker}
                    className="add-task__change-group__time"
                    style={menuActivTimePicker ?
                        { border: '1px solid #5932EA' } : null
                            ||
                            isEmptyTimeValue ?
                            null : { border: '1px solid #5932EA' }}
                    onClick={() => setMenuActivTimePicker(!menuActivTimePicker)}>
                    <TimeSvg className="add-task__time-svg"
                        style={menuActivTimePicker ?
                            { fill: '#5932EA' } : null
                                ||
                                isEmptyTimeValue ? null : { fill: '#5932EA' }} />
                    <input
                        minLength='5'
                        maxLength='5'
                        type="text"
                        readOnly
                        placeholder='Время на задачу'
                        className="priority__input-time"
                        style={isEmptyTimeValue ? null : { color: 'rgb(89, 50, 234)' }}
                        value={selectedTimeToTask()} />


                </div>
                <div className='add-task__change-group__priority'
                    ref={refClosePriorityPicker}
                    onClick={() => setMenuActivPriorityPicker(!menuActivPriorityPicker)}
                    style={menuActivPriorityPicker && window.screen.width < 480 ?
                        { border: '1px solid #5932EA' } : null}>
                    <span
                        title="Приоритет для задачи"
                        className={classPriorityArray[priority]} />
                    <p>Приоритет</p>

                </div>
                <div className='add-task__change-group__tags'
                    ref={refCloseTagsPicker}
                    onClick={() => setMenuActivTagsPicker(!menuActivTagsPicker)}
                    style={menuActivTagsPicker && window.screen.width < 480 ?
                        { border: '1px solid #5932EA' } : null}
                >
                    <span
                        title="Добавить метку к задаче"
                        className="add-task__add-tags-to-task"
                        style={tagList === null ?
                            null : tagList.length > 0 ?
                                { backgroundColor: '#5932EA' } : null}
                    />
                    <p className="add-task__change-group__tags-description">Метки</p>

                </div>
                {
                    repeatTemplate &&
                        <div ref={refTemplateDiv}>
                            {/* <div style={{width: '16px', height: '25px', marginLeft: '21px'}}> */}
                            <DDSelectRepeatTemplate scroll={scrollToAlert} alertNullPeriod={alertNullPeriod} alertNullPeriodIcon={alertNullPeriodIcon} setAlertNullPeriodIcon={setAlertNullPeriodIcon} setAlertNullPeriod={setAlertNullPeriod} setPeriod={setTemplatePeriod}/>
                        </div>
                    
                }
            </div>
            {
                menuActivDatePicker &&
                <DDSelectDate
                    addTaskButtons
                    deadlineinput
                    scrolledY={scrolledY}
                    typeOfCalendar={1}
                    active={menuActivDatePicker}
                    time={time}
                    datePayload={toNormalizeDate(date)}
                    setActive={setMenuActivDatePicker}
                    updateDataDate={updateDataDate}
                    updateDataTime={updateDataTime}
                    modalShow={false} />
            }
            {/* </div> */}
            <div className={menuActivTimePicker ? 'menu active' : 'menu'}>
                <DDSelectTimeToTask
                    active={menuActivTimePicker}
                    setActive={setMenuActivTimePicker}
                    timeToTaskHours={timeToTaskHours}
                    timeToTaskMinutes={timeToTaskMinutes}
                    updateDataTimeToTaskHours={updateDataTimeToTaskHours}
                    updateDataTimeToTaskMinutes={updateDataTimeToTaskMinutes} />
            </div>
            <div className={menuActivPriorityPicker ? 'menu active' : 'menu'} style={window.screen.width < 480 ? { right: '0%', left: '0%', transform: 'translate(0%, 0%)' } : null} onClick={() => setMenuActivPriorityPicker(false)}>
                <DDSelectPriority
                    path={'addTask'}
                    active={menuActivPriorityPicker}
                    setActive={setMenuActivPriorityPicker}
                    updateDataPriority={updateDataPriority} />
            </div>
            <div className={menuActivTagsPicker ? 'menu active' : 'menu'} onClick={() => setMenuActivTagsPicker(false)}>
                <DDSelectTags
                    path={'addTask'}
                    active={menuActivTagsPicker}
                    setActive={setMenuActivTagsPicker}
                    updateTagList={updateTagList}
                    tagList={tagList}
                    removeTagFromTagList={removeTagFromTagList} />
            </div>
            <div className="btn-container">
                <button
                    className="close-add-task-form"
                    onClick={() => props.updateData(false)}>
                    Отмена
                </button>
                <input
                    type='submit'
                    className="btn-add-task"
                    value='Добавить задачу'
                    disabled={diasbledBtn}
                    />
            </div>
            <div className="tagsList-container">
                <ul className="tagsList-list">
                    {tagList.length ?
                        tagList.map((el, index) => {
                            return <li className="tagsList-item" key={index}>
                                <span className="tagsList-item__bg" />
                                <div className="tagsList-item__container">
                                    <p className="tagsList-item__name">{el.tagName}</p>
                                    <RemoveTag className="tagsList-item__remove-item"
                                        onClick={() => removeTagFromTagList(el.tagId)} />
                                </div></li>
                        }) :
                        null}
                </ul>
            </div>

            <p className={isActiveCounter ? 'alertCounterLengthOfTitle' : 'hide'}>
                Лимит символов: {currentLenghtOfTitle} из 150
            </p>

        </form>
    </div>
)
    }
