import React from "react";
import Button from "../../../../UIcomponents/Button/Button";
import './FailureAuth.css';
import { useNavigate } from "react-router-dom";

export interface AuthProp {
    emailErr: string,
    typeAuth: string,
    setIsAuthError: Function
}

export default function FailureAuth({emailErr, typeAuth, setIsAuthError}: AuthProp) {
    const arrSocial = {'vk': 'Вконтакте', 'yandex': 'Яндекс', 'telegram': 'Телеграм'}
    const key = `${typeAuth}`
    const navigate = useNavigate()
    
    const handleForgotPass = () => {
        setIsAuthError(false)
        navigate('/forgot_password', { state: {email: emailErr}})
    }

    const handleLogin = () => {
        setIsAuthError(false)
        navigate('/login')
    }

    return ( 
        <div className="FailureAuth">
            <p className="FailureAuth__description">Пользователь с email <span className="FailureAuth__description__colored">{emailErr}</span> уже 
            существует и к нему привязан аккаунт <span className="FailureAuth__description__colored">{arrSocial[key]}</span></p>
            <div className="FailureAuth__btn-group">
                <Button title="Восстановить пароль" btnType="default" btnSize="xl" onClickFunc={() => handleForgotPass()}/>
                <Button title="Войти" btnType="filled" btnSize="xl" onClickFunc={() => handleLogin()}/>
            </div>
        </div>
    )
}