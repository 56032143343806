import React from "react";
import { ReactComponent as YaAuth} from './static/yandex.svg';
import { ReactComponent as VkOneTap} from './static/VKOneTap.svg';
import './AuthVariants.css'
import request from "../../../Requests/requests";

export default function AuthVariants() {
    // let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no`;

    const handleclick = (typeAuth: string) => {
        request.get(`${typeAuth}`)
        .then((response) => {
            window.open(response.data, 'Auth')            
        })        
    }

    return (
        <div className="AuthVariants__container">
            <p className="AuthVarinats__descr">или</p>
                <div className="AuthVariants__btn-group">
                    <button id="vkAuth" className="vkAuth" onClick={() => handleclick('/auth/vk/login')}>
                        <VkOneTap />            
                    </button>
                    <button id="YaAuth" className="YaAuth" onClick={() => handleclick('/auth/yandex/login')}>
                        <YaAuth/>            
                    </button>
                </div>
        </div>
    )
}