import { regExpAfterTomorrow, regExpDifferentDay, regExpGroups, regExpNextMonth, regExpNextWeek, regExpNextYear, regExpNoDate, regExpToday, regExpTomorrow } from "./regularExpForTime";

type useDataRecognitionFunctions = {
    setDataRecognitions: () => void;
}

const useSetDataRecognition = (
    spanElements: Element[], 
    setPriority: Function,
    setDate: Function,
    setTime: Function,
    setTaskHours: Function,
    setTaskMinutes: Function, 
    selectedValues: {
        priority: number | null,
        time: string | null,
        date: string | null ,
        timeToTaskHours: string | null,
        timeToTaskMinutes: string | null
    }, datePayload: Date ): useDataRecognitionFunctions =>  {

    const arrMonth = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 
                        'августа', 'сентября', 'октября', 'ноября', 'декабря']
    const arrMonthCutted = ['янв', 'фев', 'мар', 'апр', 'мая', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек']
    const arrPriority = ['!!1', '!!2', '!!3', '!!0']

    const dateСomparison = (dateOfMonth: Date, currentDate: Date) => {
        let date1 = Date.UTC(dateOfMonth.getFullYear(), dateOfMonth.getMonth(), dateOfMonth.getDate())
        let date2 = Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
        if (date1 > date2) {
            return date1 > date2
        } else if (date1 === date2) {
            return date1 === date2
        }
    }

    const selectOfDate = (text: string, currentDate: Date) => {        
        for (const regExp of regExpGroups.Date) {
            switch (regExp) {
                case regExpTomorrow:
                    if (text.match(regExp)) {
                        let tomorrow = new Date(currentDate)
                        tomorrow.setDate(tomorrow.getDate() + 1).toLocaleString()
                        setDate(tomorrow.toLocaleDateString())
                    }
                    break;
                case regExpToday:
                    if (text.match(regExp)) {
                        setDate(currentDate.toLocaleDateString())
                    }
                    break;
                case regExpAfterTomorrow:
                    if (text.match(regExp)) {
                        let afterTomorrow = new Date(currentDate)
                        afterTomorrow.setDate(afterTomorrow.getDate() + 2)
                        setDate(afterTomorrow.toLocaleDateString())
                    }
                    break;
                case regExpNoDate:
                    if (text.match(regExp)) {
                        setDate('')
                    }
                    break;
                case regExpNextWeek:
                    if (text.match(regExp)) {
                        const newDate = new Date(currentDate.getFullYear(), 
                                                currentDate.getMonth(), 
                                                currentDate.getDate() + 7)
                        setDate(newDate.toLocaleDateString())
                    }
                    break;
                case regExpNextMonth:
                    if (text.match(regExp)) {
                        const newDate = new Date(currentDate.getFullYear(), 
                                                currentDate.getMonth() + 1, 
                                                currentDate.getDate())
                        setDate(newDate.toLocaleDateString())
                    }
                    break;
                case regExpNextYear:
                    if (text.match(regExp)) {
                        const newDate = new Date(currentDate.getFullYear() + 1, 
                                                currentDate.getMonth(), 
                                                currentDate.getDate())
                        setDate(newDate.toLocaleDateString())
                    }
                    break;
                case regExpDifferentDay:
                    if (text.match(regExp)) {
                        let cuttedDay = text.split(' ')
                            arrMonth.map(month => {
                                if (cuttedDay[1] === month) {
                                    const newDate = new Date(currentDate.getFullYear(), 
                                                            arrMonth.indexOf(month), 
                                                            Number(cuttedDay[0]))
                                    if (dateСomparison(newDate, currentDate)) {
                                            return setDate(newDate.toLocaleDateString())
                                    } else {
                                        const newDateFuture = new Date(currentDate.getFullYear() + 1, 
                                                                        arrMonth.indexOf(month), 
                                                                        Number(cuttedDay[0]))
                                        return setDate(newDateFuture.toLocaleDateString())
                                    }
                                } else {
                                    return 0
                                }
                            })
                            arrMonthCutted.map(month => {
                                if (cuttedDay[1] === month) {
                                    const newDate = new Date(currentDate.getFullYear(), 
                                                            arrMonthCutted.indexOf(month), 
                                                            Number(cuttedDay[0]))
                                    if (dateСomparison(newDate, currentDate)) {
                                            return setDate(newDate.toLocaleDateString())
                                        
                                    } else {
                                        const newDateFuture = new Date(currentDate.getFullYear() + 1, 
                                                                        arrMonthCutted.indexOf(month), 
                                                                        Number(cuttedDay[0]))
                                        return setDate(newDateFuture.toLocaleDateString())
                                    }
                                } else {
                                    return 0
                                }
                            })
                    }
                    break;
            }
        }
    }
    

    const setDataRecognitions = () => {
        const currentDate =  datePayload
        for (const [groupName] of Object.entries(regExpGroups)) {
            const getArrGroup = spanElements.filter(el => el.getAttribute('data-match-group') === groupName)
            switch (groupName) {
                case "TimeHours":
                    if (getArrGroup.find(el => el.getAttribute('data-tracking') === 'true')) {
                        const elem = getArrGroup.find(el => el.getAttribute('data-tracking') === 'true')?.getAttribute('data-match-id') ?? ''
                        const reg: RegExp =  /(0|1[0-9]|2?[0-3])/gi
                        const splited = elem.match(reg)
                        setTaskHours(splited)
                    } 
                    // else {
                    //     setTaskHours(selectedValues.timeToTaskHours ?? '')
                    // }
                    break;
                case "TimeMinutes":
                    if (getArrGroup.find(el => el.getAttribute('data-tracking') === 'true')) {
                        const elem = getArrGroup.find(el => el.getAttribute('data-tracking') === 'true')?.getAttribute('data-match-id') ?? ''
                        const reg: RegExp = /([0-5]?[0-9])/gi
                        const splited = elem.match(reg);
                        setTaskMinutes(splited)
                    } 
                    // else {
                    //     setTaskMinutes(selectedValues.timeToTaskMinutes ?? '')
                    // }
                    break;
                case 'TaskPriority':
                    if (getArrGroup.find(el => el.getAttribute('data-tracking') === 'true')) {
                        const elem = getArrGroup.find(el => el.getAttribute('data-tracking') === 'true')
                        setPriority(arrPriority.indexOf(elem?.getAttribute('data-match-id') ?? ''));
                    } else {
                        setPriority(selectedValues.priority ?? 3)
                    }
                    break;
                case 'Date':
                    if (getArrGroup.find(el => el.getAttribute('data-tracking') === 'true')) {
                        const elem = getArrGroup.find(el => el.getAttribute('data-tracking') === 'true')
                        selectOfDate(elem?.getAttribute('data-match-id') ?? '' , currentDate)
                    } 
                    // else {
                    //     setDate(selectedValues.date ?? currentDate.toLocaleDateString())
                    // }
                    break;
                case 'TaskTime':
                    if (getArrGroup.find(el => el.getAttribute('data-tracking') === 'true')) {
                        const elem = getArrGroup.find(el => el.getAttribute('data-tracking') === 'true')?.getAttribute('data-match-id') ?? ''
                        const splited = elem.split(' ')
                        if (splited.length > 1) {
                            setTime(splited[1])
                        } else {
                            setTime(splited[0])
                        } 
                    } 
                    // else {
                    //     setTime(selectedValues.time ?? '')
                    // }
                    break;
                case 'FullDate':
                    if (getArrGroup.find(el => el.getAttribute('data-tracking') === 'true')) {
                        const elem = getArrGroup.find(el => el.getAttribute('data-tracking') === 'true')?.getAttribute('data-match-id') ?? ''
                        for (const regExp of regExpGroups.Date) {
                            const match = elem.match(regExp)
                            if (match) {
                                selectOfDate(match[0], currentDate)
                            }
                        }
                        for (const regExp of regExpGroups.TaskTime) {
                            const match = elem.match(regExp)
                            if (match) {
                                const splited = match[0].split(' ')
                                if (splited.length > 1) {
                                    setTime(splited[1])
                                } else {
                                    setTime(splited[0])
                                } 
                            }
                        }
                    } else {
                        setDate(selectedValues.date ?? new Date().toLocaleDateString())
                        setTime(selectedValues.time ?? '')
                    }
                    break;
                case 'FullTime':
                    if (getArrGroup.find(el => el.getAttribute('data-tracking') === 'true')) {
                        const elem = getArrGroup.find(el => el.getAttribute('data-tracking') === 'true')?.getAttribute('data-match-id') ?? ''
                        const reg = new RegExp('[0-9]{1,2}')
                        for (const regExp of regExpGroups.TimeHours) {
                            const match = elem.match(regExp)
                            if (match) {
                                const matchSplit = match[0].match(reg)
                                setTaskHours(matchSplit![0])
                            }
                        }
                        for (const regExp of regExpGroups.TimeMinutes) {
                            const match = elem.match(regExp)
                            if (match) {
                                const matchSplit = match[0].match(reg)
                                setTaskMinutes(matchSplit![0])            
                            }
                        }
                            
                    } else {
                        setTaskMinutes(selectedValues.timeToTaskMinutes ?? '')
                        setTaskHours(selectedValues.timeToTaskHours ?? '')
                    }

                    break;
                default:
                    break;
            }   
        }
    }
    return {setDataRecognitions}
}

export default useSetDataRecognition