import React, { useState, useEffect } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import request from '../Requests/requests';
import {Link, useNavigate} from 'react-router-dom';
import {ReactComponent as CloseBtn} from '../icons/svg/menu_ic/close_ofcanvas.svg';
// import {ReactComponent as TemplateIcon} from '../DropDownMenu/SelectRepeatTemplate/static/repeatTemplate_icon.svg';
import DonationBtn from '../UIcomponents/DonationBtn/DonationBtn';
import ModalWindow from '../ModalWindow/ModalWindow/ModalWindow';
import Cookies from 'universal-cookie';


export default function OffcanvasMobile({active, setIsAuth, setActive, avatar, updateModalState, updateTitleModal, updateDescriptionModal, updateLogoModal, updateButtonsModal}) {
    const navigate = useNavigate();
    const cookies = new Cookies()
    const [modalShow, setModalShow] = useState(false)

    function Logout() {
        ['token', 'authToken', 'firstName', 'firstname', 'position', 'lastName', 'lastname', 'liveRefreshUntil'].forEach(item => {
        localStorage.removeItem(item)
        })
        setIsAuth(false)
        setActive(false)
        cookies.remove('refreshToken');
        navigate('/login', {require: true});
    }

    useEffect(() => {
        request.get('/Account/User/', {
            params: {UserId: localStorage.getItem('token') }
        })
        .then(response =>{
            localStorage.setItem('firstName', response.data.name === null ? '' : response.data.name);
            localStorage.setItem('lastName', response.data.lastname === null ? '' : response.data.lastname);
            localStorage.setItem('position', response.data.position === null ? '' : response.data.position)
        })
    }, [])

    return (
        <>
            {
                modalShow && 
                <ModalWindow updateModalState={setModalShow}
                    modalShow={modalShow}
                    logo={true}
                    position={'center'}
                    title={'Сделайте свой вклад в развитие проекта'}>
                    <div style={{maxWidth: '444px', display: 'flex', flexDirection: 'column', gap: '10px'}}>
                    <p>Нам важна ваша поддержка 💪 <br />Пожертвования пойдут на развитие проекта и обеды разработчиков, 
                        чтобы они могли чувствовать себя еще более комфортно в своих 
                        непрерывных битвах с багами. 
                    </p>
                    <p>Но не думайте, что наша команда только ест и шутит! Мы трудимся 
                        день и ночь, чтобы создать удобный продукт, который сделает вашу 
                        жизнь и работу легче.
                    </p>
                    <p>Даже если вы передумали пожертвовать, то наши разработчики уже 
                        готовятся к 🍔 бургерным слезам.
                    </p>
                    <DonationBtn redirectTo='https://yoomoney.ru/to/4100118394034625' title='Поддержать проект'/>
                </div>
                </ModalWindow>
            }
            <Offcanvas show={active} placement='end' style={{width: '264px', padding: '12px 22px',  borderRadius: '10px 0px 0px 10px', backgroundColor: '#232360', opacity: '0.95'}}>
                <Offcanvas.Header  closeVariant='white' style={{padding: '0'}}>
                    <Offcanvas.Title style={{width: '100%', display:'flex', alignItems: 'center'}}>
                        <img className='user-avatar-mobile' src={avatar} alt=''/>
                        <div className='a-list-menu__person-info'>
                            <div className='a-list-menu__person-name'>
                                <p>{localStorage.getItem('firstName')}</p>
                                <p>{localStorage.getItem('lastName')}</p>
                            </div>
                            <div className='a-list-menu__person-post'>
                                <p>{localStorage.getItem('position')}</p>
                            </div>
                        </div>
                        <CloseBtn closeButton style={{position: 'absolute', right: '15px', stroke: '#FFF'}}></CloseBtn>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body style={{padding: '0'}}>
                <ul className='a-list-menu'>
                        {/* <div className='a-list-menu__person-data'>
                            <img className='ava' src={avatar} alt=''/>
                            <div className='a-list-menu__person-info'>
                                <div className='a-list-menu__person-name'>
                                    <p>{localStorage.getItem('firstName')}</p>
                                    <p>{localStorage.getItem('lastName')}</p>
                                </div>
                                <div className='a-list-menu__person-post'>
                                    <p>{localStorage.getItem('position')}</p>
                                </div>
                            </div>
                        </div> */}
                        <li className='a-item-menu__mobile'>
                            <span className='bg_menu-1'/>
                            <Link to="/profile" className='a-menu__mobile' >Профиль</Link>
                        </li>
                        <li className='a-item-menu__mobile'>
                            <span className='bg_menu-2'/>
                            <Link to='/statistics' className='a-menu__mobile' >Статистика</Link>
                        </li>
                        <li className='a-item-menu__mobile'>
                            <span className='bg_menu-3'/>
                            <Link to='/settings' className='a-menu__mobile' >Настройки</Link>
                        </li>
                        <li className='a-item-menu__mobile'>
                            <span className='bg_menu-4'/>
                            <Link to='/completed' className='a-menu__mobile' >Выполненные</Link>
                        </li>
                        <li className='a-item-menu__mobile'>
                            <span className='bg_menu-5'/>
                            <Link to='/removed_todo' className='a-menu__mobile'>Корзина</Link>
                        </li>
                        <li className='a-item-menu__mobile'>
                            <span className='bg_menu-6'/>
                            <Link to='/tags' className='a-menu__mobile'>Метки</Link>
                        </li>
                        {/* <li className='a-item-menu__mobile'>
                            <TemplateIcon style={{fill: '#FFF', marginRight: '10px'}}/>
                            <Link to='/automation' className='a-menu__mobile'>Автоматизация</Link>
                        </li> */}
                        {/* <li className='a-item-menu__mobile'>
                            <span className='bg_menu-7'/>
                            <span className='a-menu__mobile' href="#">Премиум</span>
                        </li> */}
                        <li className='a-item-menu__mobile' onClick={() => Logout()}>
                            <span className='bg_menu-8'/>
                            <span className='a-menu__mobile' href="#">Выйти</span>
                        </li>
                    </ul>
                    <div style={{display: 'flex', justifyContent:'center'}} onClick={() => setActive(false)}>
                        <DonationBtn 
                            updateModalState={setModalShow} 
                            title='Поддержать проект'/>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}
