export const regExpTime: RegExp = /(?<=\s|^)((в )?((([0-1][0-9]|2[0-3])(:[0-5][0-9]))))(?=\s|$)/gi;
export const regExpToday: RegExp = /(?<=\s|^)сегодня(?=\s|$)/gi;
export const regExpNoDate: RegExp = /(?<=\s|^)без\sсрока(?=\s|$)/gi;
export const regExpAfterTomorrow: RegExp = /(?<=\s|^)(?<!завтра)послезавтра(?=\s|$)/gi;
export const regExpTomorrow: RegExp = /(?<=\s|^)(?<!после)завтра(?=\s|$)/gi;
export const regExpDifferentDay: RegExp = /(?<=\s|^)(1?[0-9]|2[0-9]|3[01])\s(янв(аря)?|фев(раля)?|мар(та)?|апр(еля)?|мая|июн(я)?|июл(я)?|авг(уста)?|сен(тября)?|окт(ября)?|ноя(бря)?|дек(абря)?)(?=\s|$)/gi;
export const regExpPriority: RegExp = /(?<=\s|^)(?<!\w)!![0-3](?!\w)(?=\s|$)/g;
export const regExpTag: RegExp = /(?<=\s|^)[#]|[№]|["]|[@](?=\s|$)/g;
export const regExpHoursTask: RegExp = /(?<=\s|^)(на )?([0-1]?[0-9]|2[0-3])(\s)?час(а|ов)?(?=\s|$)/gi;
export const regExpMinutesTask: RegExp = /(?<=\s|^)(на )?([0-5]?[0-9])(\s)?минут(а|у|ы)?(?=\s|$)/gi;
export const regExpHoursTaskShort: RegExp = /(?<=\s|^)(на )?([0-1]?[0-9]|2[0-3])(\s)?ч(?=\s|$)/gi;
export const regExpMinutesTaskShort: RegExp = /(?<=\s|^)(на )?([0-5]?[0-9])(\s)?((м)|(мин))(?=\s|$)/gi;
// export const regExpFullTimeTask: RegExp = /(?<=\s|^)(на )?([0-1]?[0-9]|2[0-3])\sчас(а|ов) ([0-5]?[0-9])(\s?)минут(а|у|ы)?(?=\s|$)/gi
export const regExpNextWeek: RegExp = /(?<=\s|^)через неделю(?=\s|$)/gi;
export const regExpNextMonth: RegExp = /(?<=\s|^)через месяц(?=\s|$)/gi;
export const regExpNextYear: RegExp = /(?<=\s|^)через год(?=\s|$)/gi;

export const regExpArray: RegExp [] = [
    regExpTime,
    regExpToday,
    regExpNoDate,
    regExpTomorrow,
    regExpAfterTomorrow,
    regExpDifferentDay,
    regExpPriority,
    // regExpTag,
    regExpHoursTask,
    regExpMinutesTask,
    regExpNextWeek,
    regExpNextMonth,
    regExpNextYear
]

export const regExpGroups = {
    FullTime: [],
    FullDate: [],
    Date: [
        regExpToday,
        regExpTomorrow,
        regExpAfterTomorrow,
        regExpNoDate,
        regExpDifferentDay,
        regExpNextWeek,
        regExpNextMonth,
        regExpNextYear
    ],
    TimeHours: [
        regExpHoursTask,
        regExpHoursTaskShort
    ],
    TimeMinutes: [
        regExpMinutesTask,
        regExpMinutesTaskShort
    ],
    TaskTime: [
        regExpTime
    ],
    TaskPriority: [
        regExpPriority
    ],
    // TaskTags: [
    //     regExpTag
    // ], 
}
// old format
// export const regExpHoursTask: RegExp = /(?<=\s|^)(на )?([0-1]?[0-9]|2[0-3])(\s?)час(а?|ов)?(?=\s|$)/gi;
// export const regExpMinutesTask: RegExp = /(?<=\s|^)(на )?([0-5]?[0-9])(\s?)минут(а|у|ы)?(?=\s|$)/gi;
