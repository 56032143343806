import React, { useEffect } from "react";
import Loader from "../../../../Loader/Lodaer";

export interface AuthProp {
    typeAuth: string
}

export default function SuccessAuth({typeAuth}: AuthProp) {
    const AuthChanel = new BroadcastChannel('Auth') 
    const receivedUrl = new URL(window.location.href)

    useEffect(() => {
        if (receivedUrl.searchParams.get('code')) {
            let code = receivedUrl.searchParams.get('code')
            AuthChanel.postMessage({type: typeAuth, payload: code})
            window.close()
        }
         /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [])

    return ( 
        <Loader />
    )
}